<template lang="pug">
div
  .row
    .col-xxl-8
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('biodiversity.genus_detail') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-row.my-3
            b-col(sm="2")
              label
                | {{ $t('biodiversity.genus_name') }}
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.name")
            b-col(sm="4")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.slug" disabled="")
          b-row.my-3
            b-col(sm="2")
              label  Phylum 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.phylum")
          b-row.my-3
            b-col(sm="2")
              label  SubPhylum 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.subphylum")
          b-row.my-3
            b-col(sm="2")
              label  Class 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.class")
          b-row.my-3
            b-col(sm="2")
              label  Order 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.order")
          b-row.my-3
            b-col(sm="2")
              label  SubOrder 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.suborder")
          b-row.my-3
            b-col(sm="2")
              label  InfraOrder 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.infraorder")
          b-row.my-3
            b-col(sm="2")
              label  Family 
            b-col(sm="6")
              b-input-group(size="sm" type="text")
                b-form-input(v-model="data.family")
          b-form-group#fieldset-1(description="" :label="`${$t('biodiversity.genus_description')} (TH)`")
            ckeditor(:editor="editor" v-model="data.description_th" :config="editorConfig")
          b-form-group#fieldset-1(description="" :label="`${$t('biodiversity.genus_description')} (EN)`")
            ckeditor(:editor="editor" v-model="data.description_en" :config="editorConfig")
      b-overlay(:show="isSaving" no-wrap="")  
    .col-xxl-4
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('biodiversity.publish') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-button.mb-5(variant="primary" block="" @click="savePost") Save
        b-overlay(:show="isSaving" no-wrap="")  
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label {{ $t('biodiversity.image_attachment') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1.pt-3(description="" label="" label-for="input-1")
            #preview
              b-img.mb-3(v-if="hasImage" :src="imageSrc" fluid="" block="" rounded="")  
              b-img.mb-3(v-if="data.image && !hasImage" :src="data.image" fluid="" block="" rounded="")  
            b-button.btn-sm.mb-5(v-if="hasImage" variant="danger" @click="clearImage" block="") Delete
            b-form-file(accept="image/jpeg, image/png" v-model="pictureFile" placeholder="กรุณาเลือกไฟล์" drop-placeholder="Drop file here...")
        b-overlay(:show="isSaving" no-wrap="")  
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  name: 'biodiversity_genus_add',
  data() {
    return {
      isReady: false,
      isSaving: false,
      id: 0,
      mode: null,
      data: {
        id: 0,
        code: null,
        name: null,
        slug: null,
        description: null,
        lat: null,
        lon: null,
        province: null,
        image: null,
      },
      pictureFile: null,
      imageSrc: null,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [Essentials, Autoformat, Bold, Italic, BlockQuote, Heading, Link, List, Paragraph, Alignment],

        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
          ],
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Biodiversity Data', route: '/admin/biodiversity'},
      {title: 'Genus', route: '/admin/biodiversity/genus'},
      {title: 'Add Genus'},
    ]);

    this.getData();
  },

  methods: {
    getData() {
      if (this.$route.name == 'biodiversity.edit_genus') {
        this.mode = 'edit';
        this.$store
          .dispatch(Services.GET_GENUS_DATA, {id: this.$route.params.id})
          .then(res => {
            this.isReady = true;
            this.data = res.data[0];
          })
          .catch(error => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    savePost() {
      return new Promise((resolve, reject) => {
        if (this.data.name == null) {
          this.$bvToast.toast('Please complete the form.', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
          // reject('Please complete the form');
        } else {
          this._savePost();
          resolve();
        }
      });
    },
    _savePost() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_GENUS_DATA, this.data)
        .then(res => {
          if (res.status) {
            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(Services.UPLOAD_GENUS_IMAGE, formData)
                .then(res => {
                  if (res.status) {
                    this.data.image = res.data.image_path;
                  }
                  // console.log('Upload Banner');
                  this.isSaving = false;
                  this.pictureFile = null;
                  //Update image path
                  this.$store.dispatch(Services.SAVE_GENUS_DATA, this.data);
                })
                .catch(error => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast('Post Saved.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
            this.$router.push({
              name: 'biodiversity.list_genus',
              params: {id: this.data.id},
            });
          } else {
            this.isSaving = false;
            this.$bvToast.toast(res.message, {
              title: 'Failed!',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch(error => {
          this.isSaving = false;
          console.log(error);
        });
    },
    clearImage() {
      this.pictureFile = null;
    },
  },
  computed: {
    hasImage() {
      return !!this.pictureFile;
    },
  },
  watch: {
    pictureFile: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    'data.name': function(oldValue, newValue) {
      if (this.mode == 'add') {
        this.data.slug = this.slugify(this.data.name);
      }
    },
  },
};
</script>

<style></style>
